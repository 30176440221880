<template>
  <b-overlay :show="loading">
    <div>
      <b-card class="overflow-hidden" bg-variant="primary">
        <b-row no-gutters>
          <b-col md="6">
            <div class="">
              <h3 class="text-white">{{ data.name }}</h3>
              <div class="d-flex align-items-center mt-2">
                <div class="d-flex align-items-center text-body mr-5">
                  <feather-icon icon="ClockIcon" class="mr-50 text-white" />
                  <small class="font-weight-bold text-white">Waktu Mulai</small>
                </div>
                <div class="d-flex align-items-center text-body ml-2">
                  <feather-icon icon="ClockIcon" class="mr-50 text-white" />
                  <small class="font-weight-bold text-white">
                    Waktu Berakhir
                  </small>
                </div>
              </div>
              <div class="d-flex align-items-center mt-1">
                <div class="d-flex align-items-center text-body">
                  <small class="font-weight-bold text-white">
                    {{ getTanggal(data.info_start) }} WIB
                  </small>
                </div>
                <div class="d-flex align-items-center text-body ml-1">
                  <small class="font-weight-bold text-white">
                    {{ getTanggal(data.info_end) }} WIB
                  </small>
                </div>
              </div>
              <div class="mt-3">
                <b-button
                  class="mt-1 mb-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="light"
                  @click="mulai"
                >
                  <span>Mulai Tryout</span>
                </b-button>
              </div>
            </div>
          </b-col>
          <b-col md="6">
            <b-card>
              <b-row class="">
                <b-col>
                  <b-media vertical-align="top" class="mb-2">
                    <template #aside>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="dark"
                        class="btn-icon rounded-circle"
                        disabled
                      >
                        <feather-icon icon="UserIcon" />
                      </b-button>
                    </template>
                    <span>Peserta</span>
                    <br />
                    <b>{{ data.sum_quota }}/{{ data.max_quota }}</b>
                  </b-media>
                </b-col>
                <b-col>
                  <b-media vertical-align="top" class="mb-2">
                    <template #aside>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="dark"
                        class="btn-icon rounded-circle"
                        disabled
                      >
                        <feather-icon icon="ClockIcon" />
                      </b-button>
                    </template>
                    <span>Total Waktu</span>
                    <br />
                    <b>{{ totalTimer }} menit</b>
                  </b-media>
                </b-col>
                <b-col>
                  <b-media vertical-align="top" class="mb-2">
                    <template #aside>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="dark"
                        class="btn-icon rounded-circle"
                        disabled
                      >
                        <feather-icon icon="FileTextIcon" />
                      </b-button>
                    </template>
                    <span>Total Soal</span>
                    <br />
                    <b>{{ data.sum_soal }} Soal</b>
                  </b-media>
                </b-col>
              </b-row>
            </b-card>
            <!-- <b-button variant="light float-right"> <feather-icon icon="ClockIcon" /> <span class="text-muted"> Tryout Dimulai </span> <span class="text-danger"> 7 hari, 3 jam, 5 menit </span> </b-button> -->
          </b-col>
        </b-row>
      </b-card>
      <!-- <b-card-text class="card-text">
      Lorem ipsum dolor sit amet consectetur. Accumsan rutrum leo semper eget orci vitae et vehicula fusce. Tincidunt est quisque non sed aenean cras. Nunc vestibulum natoque quam urna lectus. Eget aliquam aliquet amet varius interdum.
      Tortor sed a faucibus quis quis sodales vestibulum est viverra. Urna at condimentum egestas sit lectus. Sagittis sed non neque diam eget amet tellus magnis egestas. Commodo tortor sed duis pharetra vitae mi eu pulvinar elit.
    </b-card-text> -->
      <b-row class="mt-2">
        <b-col lg="6" cols="12">
          <b-card
            border-variant="primary"
            :header="'Subtest(' + data.sum_subtest + ')'"
            header-bg-variant="primary"
            header-text-variant="white"
          >
            <b-card-body>
              <app-collapse v-if="dataSubtes[0] != null">
                <div class="" v-for="(data, i) in dataSubtes" :key="i">
                  <app-collapse-item :title="data.name" v-if="data.name">
                    <ul style="padding: 0;">
                      <li
                        v-for="(items, i) in data.items"
                        :key="i"
                        class="mt-1"
                      >
                        <b>{{ items.nama_ujian }}</b>
                        <br />
                        <feather-icon icon="ClockIcon" class="mr-50" />
                        <small class="font-weight-bold">
                          {{ items.timer }} Menit
                        </small>
                        <feather-icon icon="FileTextIcon" class="mr-50 ml-2" />
                        <small class="font-weight-bold">
                          {{ items.sum_soal }} Soal
                        </small>
                      </li>
                    </ul>
                  </app-collapse-item>
                  <ul style="padding: 0;" v-else>
                    <li>
                      <b>{{ data.nama_ujian }}</b>
                      <br />
                      <feather-icon icon="ClockIcon" class="mr-50" />
                      <small class="font-weight-bold">
                        {{ data.timer }} Menit
                      </small>
                      <feather-icon icon="FileTextIcon" class="mr-50 ml-2" />
                      <small class="font-weight-bold">
                        {{ data.sum_soal }} Soal
                      </small>
                    </li>
                  </ul>
                </div>
              </app-collapse>
              <div v-else>
                <h5 class="text-danger text-center mt-2">Tidak ada Subtest</h5>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col lg="6" cols="12">
          <b-card
            border-variant="primary"
            header="Berikut petunjuk pengerjaan"
            header-bg-variant="primary"
            header-text-variant="white"
          >
            <b-card-body>
              <b v-if="data.instruction" v-html="data.instruction"></b>
              <b v-else>
                <ol style="padding: 0;">
                  <li>Tryout ini akan terbagi menjadi beberapa subtes</li>
                  <li>
                    Sesi dikerjakan sesuai urutan dan dimulai saat kamu menekan
                    tombol ‘Mulai’
                  </li>
                  <li>
                    Sesuai dengan peraturan resmi UTBK 2023, kamu hanya dapat
                    mengerjakan tiap komponen dalam durasi yang tersedia
                  </li>
                  <li>
                    Setelah mengerjakan semua subtes, pastikan klik tombol
                    “kumpulkan”
                  </li>
                </ol>
              </b>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCollapse,
  BButton,
  VBToggle,
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BCardImg,
  BImg,
  BBadge,
  BRow,
  BCol,
  BMedia,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BOverlay,
    BButton,
    BCard,
    BCardHeader,
    BCollapse,
    BCardBody,
    BCardTitle,
    BCardImg,
    BImg,
    BBadge,
    BRow,
    BCol,
    BMedia,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  computed: {
    tryoutDetail() {
      return this.$store.state.tryout.detail
    },
  },
  data() {
    return {
      totalTimer: 0,
      data: {},
      dataSubtes: [],
      loading: false,
    }
  },
  methods: {
    getData() {
      this.loading = true
      let id = this.tryoutDetail.id
      this.$store
        .dispatch('paketTryout/detail', id)
        .then((res) => {
          this.loading = false
          this.data = res.data
          this.$store.commit('paketTryout/SET_DETAIL', res.data)
          this.getSubtes()
        })
        .catch((err) => {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Error `,
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message,
            },
          })
        })
    },
    getTotalTimer(subtests) {
      let totalTimer = 0
      subtests.forEach(item => {
        if( item.items && item.items.length > 0 ) {
          const totalTimerSubtest = item.items.reduce((total, subtest) => total += parseInt(subtest.timer),0)
          totalTimer += totalTimerSubtest
        }
        else {
          totalTimer += parseInt(item.timer)
        }
      })

      return totalTimer
    },
    getSubtes() {
      let params = {
        paket_id: this.data.id,
      }
      this.$store
        // .dispatch("paketTryout/pSubtest", params)
        .dispatch('ujian/subtesPaket', params)
        .then((res) => {
          this.dataSubtes = res.data.data
          this.totalTimer = this.getTotalTimer(this.dataSubtes)
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Error `,
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message,
            },
          })
        })
    },
    mulai() {
      // this.$router.push({ name: "tryout-detail-subtest" });
      window.location = 'tryout-detail-subtest'
      // window.location.reload();
    },
  },
  created() {
    if (this.tryoutDetail.name == undefined) {
      this.$router.go(-1)
    }
    this.getData()
  },
}
</script>
